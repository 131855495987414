import { AboutInvestment } from 'sections/AboutInvestment/AboutInvestment';
import { BottomHomeHero } from 'sections/BottomHomeHero/BottomHomeHero';
import { Flats } from 'sections/Flats/Flats';
import { GeneralContractor } from 'sections/GeneralContractor/GeneralContractor';
import { HomeGallery } from 'sections/HomeGallery/HomeGallery';
import { HomeHero } from 'sections/HomeHero/HomeHero';
import { Localization } from 'sections/Localization/Localization';
import Layout from 'layout/index';
import React, { useEffect, useMemo } from 'react';
import { useTheme } from 'layout/ThemeContext';
import { getDisabledPages } from 'utils/utils';

interface HomePageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: HomePageProps) => {
    const aboutDeveloper =
        pageContext?.data?.allWp?.nodes[0]?.themeSettings?.globalComponents
            .aboutDeveloper;
    const homeData = pageContext?.data?.wpInvestment?.investment?.homePage;

    const flatsData =
        pageContext?.data?.allWpInvestment?.edges &&
        useMemo(() => {
            return pageContext?.data?.allWpInvestment?.edges
                ?.map((item: any) => ({
                    ...item.node.investmentFlat,
                    buildingFloor: item.node.investmentFlat.buildingFloor.name,
                    flatAmenities: item.node.investmentFlat.flatAmenities?.name,
                    flatStatus: item.node.investmentFlat.flatStatus?.name,
                    investmentBuilding:
                        item.node.investmentFlat.investmentBuilding.name,
                    priceMeter: item.node.investmentFlat.flatPrice
                        ? Math.round(
                              item.node.investmentFlat.flatPrice /
                                  item.node.investmentFlat.flatArea,
                          )
                        : null,
                }))
                ?.filter((item: any) => item.flatStatus == 'Wolne')
                ?.sort(() => Math.random() - 0.5)
                ?.slice(0, 6);
        }, [pageContext?.data?.allWpInvestment?.edges]);

    const pageTitle = `${pageContext?.title}`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            location={location}
            pageTitle={pageTitle}
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <HomeHero
                    headerDescription={homeData?.headerDescription}
                    headerImage={homeData?.headerImage}
                    headerTitle={homeData?.headerTitle}
                    investmentSlug={pageContext?.slug}
                />
                <BottomHomeHero data={homeData?.advantages} />
                <AboutInvestment
                    aboutInvestmentDescription={
                        homeData?.aboutInvestmentDescription
                    }
                    aboutInvestmentImageDesktop={
                        homeData?.aboutInvestmentImageDesktop
                    }
                    aboutInvestmentImageMobile={
                        homeData?.aboutInvestmentImageMobile
                    }
                    aboutInvestmentKeyFeatures={
                        homeData?.aboutInvestmentKeyFeatures
                    }
                    aboutInvestmentTitle={homeData?.aboutInvestmentTitle}
                    investmentSlug={pageContext?.slug}
                />
                <Flats
                    data={flatsData}
                    flatsDescription={homeData?.flatsDescription}
                    flatsTitle={homeData?.flatsTitle}
                    investmentSlug={pageContext?.slug}
                />
                <HomeGallery
                    displayButton
                    gallery={homeData?.gallery}
                    galleryDescription={homeData?.galleryDescription}
                    galleryTitle={homeData?.galleryTitle}
                    investmentSlug={pageContext?.slug}
                />
                <Localization
                    investmentSlug={pageContext?.slug}
                    locationDescription={homeData?.locationDescription}
                    locationImage={homeData?.locationImage}
                    locationTitle={homeData?.locationTitle}
                />
                <GeneralContractor
                    description={aboutDeveloper?.description}
                    image={aboutDeveloper?.image}
                    link={aboutDeveloper?.link}
                    title={aboutDeveloper?.title}
                />
            </>
        </Layout>
    );
};
